import React, { useState, useEffect, useRef } from 'react';
import { Paper, TextField, Container, Typography, Avatar, InputAdornment, CircularProgress, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import JumpingDots from '../JumpingDots';
import SettingsIcon from '@mui/icons-material/Settings';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const ChatContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Align messages to the bottom
  height: '100vh',
  padding: theme.spacing(2),
  // backgroundColor: '#F4F4F4',
  overflowY: 'auto', // Make the container scrollable
}));

const ChatBubble = styled('div')(({ theme, sender }) => ({
  // marginBottom: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: "25px",
  backgroundColor: sender !== 'bot' ? '#ffffff' : 'none',
  color: sender === 'bot' ? '#333' : '#333',
  alignSelf: sender === 'bot' ? 'flex-start' : 'flex-end',
  display: 'flex',
  alignItems: 'center',
  border: sender === 'bot'? 'none': "1px solid #e2e2e2"
}));

const InputContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Logo = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const Chatbot = () => {
  const [inputMessage, setInputMessage] = useState('');
  const [chatMessages, setChatMessages] = useState(dummyChat);
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(false);

  useEffect(() => {
    if (!isMounted.current) {
      console.log('Component is mounted for the first time');
      isMounted.current = true;
      clearMemory();
      function clearMemory() {
        // Define the URL of your Flask endpoint
        const url = 'https://7sjnxddqec.execute-api.us-east-1.amazonaws.com/clear_memory';
      
        // Send a POST request to the endpoint
        fetch(url, {
          method: 'POST',
        })
          .then((response) => response.json())
          .then((data) => {
            // Handle the response from your Flask application
            console.log(data.message); // Display the response message
            getIntialMessage();
            const dummyChat = [
              // { id: 1, sender: 'user', message: 'Hello there!' },
              { id: 2, sender: 'bot', message: 'Hi Ankit! Congratulations on completing three months in the company. How was your experience?' },
            ];
            setChatMessages(dummyChat);
          })
          .catch((error) => {
            console.error('Error:', error);
            getIntialMessage();
            const dummyChat = [
              // { id: 1, sender: 'user', message: 'Hello there!' },
              { id: 2, sender: 'bot', message: 'Hi Ankit! Congratulations on completing three months in the company. How was your experience?' },
            ];
            setChatMessages(dummyChat);
          });
      }


      

      async function getIntialMessage(){
        let initialMessage = "Act like an empathetic employee's assistant whose role is to figure out employee's motivation levels. If an employee is not happy the assistant should ask crisp and concise follow-up questions to root cause the underlying issues eg if the employee indicates low job satisfaction, you need to ask a question to know what's causing lower job satisfaction. Once an employee indicates a high-level theme of dis-satisfaction ,  then immediately end the conversion gracefully. At the end of conversation, thank and ask if emplooyee wants to share anything else. Now, Prompt me with the following question \"Hi Ankit, congrats on completing 3 months in your job. How are you feeling ?\"";

  try {
    const response = await fetch('https://7sjnxddqec.execute-api.us-east-1.amazonaws.com/chatbot', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: initialMessage,
      }),
    });
    
          if (response.ok) {
            const responseData = await response.json();
              // const botResponse = responseData.response.text;
              console.log(responseData)
              // const botResponse = extractBotResponse(responseData.response.text);
    
              // const newBotMessage = { id: chatMessages.length + 1, sender: 'bot', message: botResponse };
              
          } else {
            // Handle API error
          }
        } catch (error) {
          // Handle fetch error
        }finally {
          setLoading(false); // Reset loading state after API response is received
        }

      }
    } else {
      console.log('Component is updated');
    }

    return () => {
      console.log('Component is unmounting or about to re-run');
    };
  }, []);

  const handleInputKeyPress = async (e) => {
    if ((e.key === 'Enter' || e.type === 'click') && inputMessage.trim() !== '') {
      setLoading(true);
      const newMessage = { id: chatMessages.length + 1, sender: 'user', message: inputMessage };
      setChatMessages([...chatMessages, newMessage]);
      setInputMessage('');


    try {
      const response = await fetch('https://7sjnxddqec.execute-api.us-east-1.amazonaws.com/chatbot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: inputMessage,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
          // const botResponse = responseData.response.text;
          console.log(responseData)
          const botResponse = extractBotResponse(responseData.response);

          const newBotMessage = { id: chatMessages.length + 1, sender: 'bot', message: botResponse };
          setChatMessages([...chatMessages, newMessage, newBotMessage]);
          setInputMessage('');
      } else {
        // Handle API error
      }
    } catch (error) {
      // Handle fetch error
    }finally {
      setLoading(false); // Reset loading state after API response is received
    }
  }
  };

  const extractBotResponse = (responseText) => {
    const botTextIndex = responseText.indexOf('Bot:');
    if (botTextIndex !== -1) {
      return responseText.substring(botTextIndex + 4).trim(); // +4 to skip "Bot:"
    }
    return responseText; // If "Bot:" keyword not found, return the entire response
  };

  return (
    <ChatContainer maxWidth="md" sx={{backgroundColor:"#e1e1e130", borderRadius:"25px", border:"1px solid #e2e2e2"}}>
      <div>
    <Box sx={{border:"1px solid #e2e2e2", borderRadius:"25px", padding:"12px", display:"flex", alignItems:"center", width: "fit-content", margin:"1rem 0", backgroundColor:"white"}}>
      <FiberManualRecordIcon style={{ fontSize: '16px', color: '#00FF38' }} />
      <Typography sx={{padding:"0 1rem"}}>
        Melody
      </Typography>
      <SettingsIcon/>
    </Box>
      <div>
      {chatMessages.map((message) => (
        <ChatBubble key={message.id} sender={message.sender}>
          {message.sender === 'bot' ? (
            <Logo alt="Bot" src="https://i.ibb.co/mtGbdHr/dummy1.png" /> // Replace with your bot's logo image
          ) : (
            <Logo alt="User" src="https://i.ibb.co/vLYkgFg/dummy2.png" /> // Replace with your user's logo image
          )}
          <Typography variant="body1">{message.message}</Typography>
        </ChatBubble>
      ))}
      </div>
      </div>
      <div>
      {loading?(<ChatBubble  sender={'bot'}>
            <Logo alt="Bot" src="bot_logo.png" />
            <JumpingDots />
            {/* <CircularProgress size={24} color="primary" /> */}
          {/* <Typography variant="body1">{"hello"}</Typography> */}
        </ChatBubble>):(<></>)}
      {/* <InputContainer variant="outlined"> */}
        <TextField
        sx={{backgroundColor:"white",}}
          fullWidth
          label="Type your message..."
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          onKeyPress={handleInputKeyPress}
          id="chatInput"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {loading ? (
                  <CircularProgress size={24} color="primary" /> // Show loading animation
                ) : (
                  <IconButton onClick={handleInputKeyPress} color="primary">
                    <SendIcon />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
        </div>
      {/* </InputContainer> */}
    </ChatContainer>
    
  );
};

const dummyChat = [
  // { id: 1, sender: 'user', message: 'Hello there!' },
  // { id: 2, sender: 'bot', message: 'Hi Ankit! Congratulations on completing three months in the company. How was your experience?' },
];

export default Chatbot;


//v2

// import React from 'react';
// import { Paper, TextField, Container, Typography, Avatar } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const ChatContainer = styled(Container)(({ theme }) => ({
//   display: 'flex',
//   flexDirection: 'column',
//   justifyContent: 'space-between',
//   height: '100vh',
//   padding: theme.spacing(2),
//   backgroundColor: '#F4F4F4', // Background color for the chat container
// }));

// const ChatBubble = styled('div')(({ theme, sender }) => ({
//   marginBottom: theme.spacing(1),
//   padding: theme.spacing(1),
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: sender === 'bot' ? '#EFEFEF' : '#B4E3E3', // Bot and user chat bubble colors
//   color: sender === 'bot' ? '#333' : '#333', // Text color for both sender types
//   alignSelf: sender === 'bot' ? 'flex-start' : 'flex-end', // Align bot messages to the left, user messages to the right
//   display: 'flex',
//     alignItems: 'center'
// }));

// const InputContainer = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

// const Logo = styled(Avatar)(({ theme }) => ({
//   marginRight: theme.spacing(1),
// }));

// const dummyChat = [
//   { id: 1, sender: 'user', message: 'Hello there!' },
//   { id: 2, sender: 'bot', message: 'Hi! How can I assist you today?' },
//   { id: 3, sender: 'user', message: 'I have a question about your products.' },
//   { id: 4, sender: 'bot', message: 'Sure, feel free to ask your question.' },
//   { id: 5, sender: 'bot', message: 'Sure, feel free to ask your question.' },
//   // Add more dummy messages here
// ];

// const ChatbotUI = () => {
//   return (
//     <ChatContainer maxWidth="md">
//       {dummyChat.map((message) => (
//         <ChatBubble key={message.id} sender={message.sender}>
//           {message.sender === 'bot' ? (
//             <Logo alt="Bot" src="bot_logo.png" /> // Replace with your bot's logo image
//           ) : (
//             <Logo alt="User" src="user_logo.png" /> // Replace with your user's logo image
//           )}
//           <Typography variant="body1">{message.message}</Typography>
//         </ChatBubble>
//       ))}
//       <InputContainer variant="outlined">
//         <TextField fullWidth label="Type your message..." id="chatInput" />
//       </InputContainer>
//     </ChatContainer>
//   );
// };

// export default ChatbotUI;


//v1

// import React from 'react';
// import { Paper, TextField, Container, Typography } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const ChatContainer = styled(Container)(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(2),
//   overflowY: 'auto',
// }));

// const ChatBubble = styled('div')(({ theme }) => ({
//   marginBottom: theme.spacing(2),
//   padding: theme.spacing(1),
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: theme.palette.primary.light,
// }));

// const InputContainer = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(2),
// }));

// const dummyChat = [
//   { id: 1, sender: 'user', message: 'Hello there!' },
//   { id: 2, sender: 'bot', message: 'Hi! How can I assist you today?' },
//   { id: 3, sender: 'user', message: 'I have a question about your products.' },
//   { id: 4, sender: 'bot', message: 'Sure, feel free to ask your question.' },
//   // Add more dummy messages here
// ];

// const Chatbot = () => {
//   return (
//     <Container
//       maxWidth="md"
//       style={{
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'space-between',
//         height: '100vh',
//       }}
//     >
//       <ChatContainer>
//         {dummyChat.map((message) => (
//           <ChatBubble
//             key={message.id}
//             style={{
//               backgroundColor: message.sender === 'bot' ? '#EFEFEF' : '#007BFF',
//               color: message.sender === 'bot' ? '#333' : '#FFF',
//             }}
//           >
//             <Typography variant="body1">{message.message}</Typography>
//           </ChatBubble>
//         ))}
//       </ChatContainer>
//       <InputContainer variant="outlined">
//         <TextField fullWidth label="Type your message..." id="chatInput" />
//       </InputContainer>
//     </Container>
//   );
// };

// export default Chatbot;

